.sidebar {
  flex: 1;
  border-right: 0.5px solid #E6E6E6;
  height: 160vh;
  background-color: white;

  .sidebarWrapper {
    padding: 20px;
    color: #1C1C1C;
    
  }
  
  .sidebarMenu {
    margin-bottom: 10px;
    
  }
  
  .sidebarTitle {
    font-size: 13px;
    color: #999999;
    margin-left: 20px;

  }
  
  .sidebarList {
    list-style: none;
    padding: 5px;
   
  }
  .link{
    text-decoration: none;
  }
  
  .sidebarListItem {
    padding: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 48px;
    margin-left: 30px;
    line-height: 20px;
   width: 180px;
   left: 0px;
   top: 104px;
   border-radius: 8px;
   color: #1C1C1C;
  }
.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgba(254, 246, 218, 1);
}


  
  .sidebarIcon{
    
height: 23px;
width: 23px;
left: 3px;
top: 2.5126953125px;
border-radius: 0px;

      margin-right: 5px;
  }
  
  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #6439ff;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {



          width: 180px;
          height: 48px;


          background: #FEF6DA;
          border-radius: 8px;

          /* Inside auto layout */

          align-self: stretch;

        }

       
      }
    }
  }



  .customtxt {
    /* Dashboards */
    width: 80px;
    height: 20px;
    margin-top: 28px;
    margin-left: 35px;
    margin-right: 104px;

    /* 14/Regular */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: justify;

    /* Neutral/400 */

    color: #999999;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }



  .btn {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    //styleName: 16/Semibold;
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0em;
text-align: center;

    width: 170px;
    height: 44px;

    /* Primary/500 */

    background: #FFC700;
    box-shadow: 0px 8px 16px rgba(164, 128, 3, 0.3);
    border-radius: 8px;
    border: none;
    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    gap: 8px;
    margin-top: 50px;
    margin-left: 16px;
    margin-right: 16px;
    width: 180px;
    height: 177px;

    /* Primary/100 */

    background: #FEF6DA;
    border-radius: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
}