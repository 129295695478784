.item{
    display: flex;
    padding: 6px 10px 6px 10px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
}
.icon{
    width: 18px;
    height:18.49px;
    margin-top:22.51px;
     margin-left: 31px;
     margin-right: 163px;

}
.menutxt{
position: absolute;

width: 212px;
height: 1024px;
margin-top:22.51px;
left: 64px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

display: flex;
align-items: center;
text-align: justify;
}
