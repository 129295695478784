.navbar {
  height: 50px;
  border-bottom: 0.5px solid #E6E6E6;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
      padding: 5px;
      background: #F2F2F2;
      border-radius: 8px;
      border:none;
      width: 285px;
      height: 28px;
      input {
        border: none;
        outline: none;
        color: #CCCCCC;
        background: #F2F2F2;
        &::placeholder {
          color: #CCCCCC;
          font-size: 14px;
        }
      }
    }

    .items {
      display: flex;
      align-items: center;
      

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}
