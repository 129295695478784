.datatable {
  height: 800px;
  padding: 10px;
  background-color: white;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: #000000;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .typecell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 4px;
    color: #1C1C1C;

    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;



    /* Primary/200 */

    background: #FFEA9E;
    border-radius: 4px;


  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.None {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 12px;

      width: 116px;
      height: 26px;

      /* Neutral/100 */

      background: #F2F2F2;
      border-radius: 18px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
    }

    &.Contract {
      /* Label */


      /* Auto layout */

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 12px;

      width: 116px;
      height: 26px;

      background: rgba(90, 194, 124, 0.1);
      border-radius: 18px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
    }

    &.Control {
      /* Auto layout */

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 12px;

      width: 116px;
      height: 26px;

      background: rgba(238, 125, 121, 0.2);
      border-radius: 18px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
    }

    &.Hot {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 12px;

      width: 116px;
      height: 26px;

      background: rgba(101, 157, 245, 0.2);
      border-radius: 18px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1
    }

    &.Warn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 12px;

      width: 116px;
      height: 26px;

      background: rgba(186, 171, 244, 0.2);
      border-radius: 18px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
    }
  }

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 149px;
    height: 44px;

    /* Primary/500 */

    background: #FFC700;
    box-shadow: 0px 8px 16px rgba(164, 128, 3, 0.3);
    border-radius: 8px;
    border: none;
    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .container {
    height: 60px;
    margin-top: 24px;
  }

  .wrapper {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left {
    gap: 8px;
    flex: 1;
    display: flex;
    align-items: center;

  }

  .center {
    flex: 1;
  }

  .rightside {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .custombtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 85px;
    height: 44px;

    /* Neutral/100 */

    background: #F2F2F2;
    border-radius: 12px;
    border: none;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .nullbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 85px;
    height: 44px;

    /* Neutral/100 */

    background: #Ffffff;
    border-radius: 12px;
    border: 1px solid #E6E6E6;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 4px;
    isolation: isolate;

    width: 200px;
    height: 35px;

    /* Mono/White */

    background: #FFFFFF;
    /* Neutral/200 */

    border: 1px solid #E6E6E6;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    input {
      border: none;
      outline: none;
      color: #999999;

      &::placeholder {
        color: #CCCCCC;
        font-size: 14px;
      }
    }
  }

  .tableHeader {

    /* Auto layout */


    width: 100%;
    height: 960px;
    margin-top: 25px;
    margin-left: 24px;
    margin-right: 24px;

    /* Table Header */

    background: #F9FBFC;
    /* Neutral/200 */

    border-bottom: 1px solid #E6E6E6;
    border-radius: 8px 8px 0px 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}