.chkcontainer{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
  }
  
  .switch{  
    position:relative;
    width: 208px;
    height: 32px;
    border-radius:10px;
    padding:4px;
  }
  .switch input{
    appearance:none;
    width: 208px;
    height: 32px;
    border-radius:10px;
    outline:none;
    border: 1px solid #000;
  
  }
  .switch input::before,
  .switch input::after{
    z-index:2;
    position:absolute;
    top:56%;
    transform:translateY(-50%);
    font-weight:bolder;
  }
  .switch input::before{
    content:"Mailed";
    left:20px;
  }
  .switch input::after{
    content:"In Person";
    color:#000;
    right:20px;
  }
  .switch input:checked{
  }
  .switch label{
    z-index:1;
    position:absolute;
    top:10px;
    bottom:4px;
    border-radius:8px;  
  
  }
  
  .switch input{
    transition:0.25s;
  }
  .switch input:checked::after,
  .switch input:checked::before{
    color:#000;
    transition:color 0.5s;
  }
  .switch input:checked+label{
    left:10px;
    right:100px;
    background:#FFD02A;
    transition:left 0.5s,right 0.4s 0.2s;
  }
  
  .switch input:not(:checked){
    transition:  0.4s;
  }
  .switch input:not(:checked)::before{
    color:#000;
    transition:color 0.5s;
  }
  .switch input:not(:checked)::after{
    color:#000;
    transition:color 0.5s 0.2s;
  }
  .switch input:not(:checked)+label{
    left:100px;
    right:10px;
    background:#FFD02A  ;
    transition:left 0.4s 0.2s,right 0.5s, background 0.35s;
  }
  