.datatable {
    height: 800px;
    padding: 10px;

    .datatableTitle {
        width: 100%;
        font-size: 24px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
    }

    .cellWithImg {
        display: flex;
        align-items: center;

        .cellImg {
            width: 12px;
            height: 13px;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .typecell {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 4px;
        color: #1C1C1C;

        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;



        /* Primary/200 */

        background: #FFEA9E;
        border-radius: 4px;


    }

    .cellWithStatus {
        padding: 5px;
        border-radius: 5px;

        &.Yes {
            /* Auto layout */

/* Auto layout */
color: #59A8D4;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 12px;

width: 98px;
height: 26px;

background: #E2F5FF;
border-radius: 18px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
        }

        &.No {
           /* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 12px;
color: #FF4747;
width: 98px;
height: 26px;

background: #FBF0EF;
border-radius: 18px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
        }

        &.Completed {
          
/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 12px;
color: #4AA785;
width: 98px;
height: 26px;

background: #DEF8EE;
border-radius: 18px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
        }


    }

    .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        width: 149px;
        height: 44px;

        /* Primary/500 */

        background: #FFC700;
        box-shadow: 0px 8px 16px rgba(164, 128, 3, 0.3);
        border-radius: 8px;
        border: none;
        /* Inside auto layout */

        flex: none;
        align-self: stretch;
        flex-grow: 0;
    }
    .arrowbtn{
        box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

width: 40px;
height: 40px;

/* Mono/White */

background: #FFFFFF;
/* Neutral/200 */

border: 1px solid #E6E6E6;
border-radius: 8px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
    }
    .container {
        height: 60px;
        width: 100%;
    }

    .wrapper {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left {
        gap: 8px;
        flex: 1;
        display: flex;
        align-items: center;

    }

    .center {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 18px;

    }
    .cellwithmethod{
        /* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 4px;
gap: 4px;

width: 20px;
height: 20px;

/* Primary/50 */

background: #FFFBEE;
border-radius: 100px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
    }
    .thisweek{
        /* This Week */


width: 100px;
height: 20px;

/* 14/Medium */

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
justify-content: center;
display: flex;
align-items: center;

/* Neutral/600 */

color: #868686;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
    }
    .date{
        /* Jun 06 - 12 */


width: 112px;
height: 28px;

/* Sub Heading/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
/* identical to box height, or 140% */

display: flex;
align-items: center;

color: #000000;


/* Inside auto layout */

    }
    .centerbody{
        display: flex;
    }
    .Ctxt{
        display:flex;
        
        flex-direction: column;
    }

    .rightside {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .custombtn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        width: 85px;
        height: 44px;

        /* Neutral/100 */

        background: #F2F2F2;
        border-radius: 12px;
        border: none;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .nullbutton {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        width: 85px;
        height: 44px;

        /* Neutral/100 */

        background: #Ffffff;
        border-radius: 12px;
        border: 1px solid #E6E6E6;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        gap: 4px;
        isolation: isolate;

        width: 200px;
        height: 35px;

        /* Mono/White */

        background: #FFFFFF;
        /* Neutral/200 */

        border: 1px solid #E6E6E6;
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;

        input {
            border: none;
            outline: none;
            color: #999999;

            &::placeholder {
                color: #CCCCCC;
                font-size: 14px;
            }
        }
    }

    .tableHeader {

        /* Auto layout */


        width: 1180px;
        height: 960px;
        margin-top: 25px;
        margin-left: 24px;
        margin-right: 24px;

        /* Table Header */

        background: #F9FBFC;
        /* Neutral/200 */

        border-bottom: 1px solid #E6E6E6;
        border-radius: 8px 8px 0px 0px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
}