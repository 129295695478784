.datatable {
    height: 800px;
    padding: 10px;

    .container {
        height: 60px;
        width: 100%;
    }

    .wrapper {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left {
        gap: 8px;
        flex: 1;
        display: flex;
        align-items: center;

    }
    .title{
        /* Home */


width: 1180px;
height: 36px;

/* Headings/24 Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

color: #000000;



    }
    .Card{
        /* Frame 427319170 */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 24px;

width: 465px;
height: 870px;

/* Mono/White */

background: #ffffff;
/* Neutral/200 */

border: 1px solid #E6E6E6;
/* Layer Shadow */

box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
    }

    .Frame{
        /* Frame 427319191 */


/* Auto layout */

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 8px;
gap: 12px;

width: 414px;
height: 160px;

/* Bg */

background: #F7F6F5;
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
    }
    .frametxt{
        /* My Morning Routine */


width: 156px;
height: 28px;

/* 16/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 28px;
/* identical to box height, or 175% */

display: flex;
align-items: center;

color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

    }

    .centerbody {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 40px;

    }
    
    .arrowbtn{
        box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

width: 40px;
height: 40px;

/* Mono/White */

background: #FFFFFF;
/* Neutral/200 */

border: 1px solid #E6E6E6;
border-radius: 8px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
    }
    .Ctxt{
        display:flex;
        
        flex-direction: column;
    }
    

    .secondFrame
    {   
box-sizing: border-box;
display: flex;
margin-top: -10px;
flex-direction: column;
width: 430px;
height: 160px;

border: 3px solid #E6E6E6;
/* Layer Shadow */

border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
    }
    .Calendar{
        width: 100%;
        padding: 10px;
        display: flex;   
    }
    .calendarrow{
    display: flex;   
    gap: 8px;
    }   
    
    input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
       
      }
      
      .checkbox {
        display: inline-block;
        height: 20px;
        width: 20px;
        background: #fff;
        border: 2px #ddd solid;
        border-radius:20px;
        margin-right: 4px;
        margin-left: 15px;
        }
      
      .checkbox--active {
        border-color: #FFC700 ;
        background: #FFC700
;
      }
      .scheckbox{
        height: 20px;
        width: 20px;
        background: #fff;
        border: 2px #ddd solid;
        border-radius:20px;
        margin-top: -65px;
        margin-left: 10px;
    
      }
      .scheckbox--active{
        border-color: #FFC700 ;
        background: #FFC700
      }
      .gratidudediv{
        display: flex;
        flex-direction: row;
        margin-top: 10px;

      }
      .stxt{
        /* Gratitudes */

/* 16/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
margin-left: 24px;
color: #000000;
      }
      .inputtxt{
        
        display: flex;
        flex-direction: column;
      }
      input{
        
width: 100px;
height: 20px;

/* 14/Regular */

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


/* Neutral/600 */

color: #868686;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
        border:none;
      }

      .thirdFrame
    {   
box-sizing: border-box;
display: flex;
margin-top: -10px;

flex-direction: column;
width: 430px;
height: 50px;

border: 3px solid #E6E6E6;
/* Layer Shadow */

border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
    }
      .text{
        
    width: 54px;
    height: 20px;
    
    /* 14/Regular */
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 143% */
    
    display: flex;
    align-items: center;
    
    /* Neutral/900 */
    
    color: #1C1C1C;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
      }
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: -245px;
    }
    .weekanalysis{
    /* Frame 427319172 */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: column;
padding: 12px;
gap: 12px;

width: 218.33px;
height: 204px;

/* Mono/White */

background: #FFFFFF;
/* Neutral/200 */

border: 1px solid #E6E6E6;
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
    }
    .frames{
      display: flex;
      gap: 15px;
    }
.thinginweek{
  /* Frame 427319176 */

margin-top: 15px;
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px;
gap: 12px;

width: 687px;
height: 84px;

/* Mono/White */

background: #FFFFFF;
/* Neutral/200 */

border: 1px solid #E6E6E6;
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.realestatereview{
  /* Frame 427319177 */


box-sizing: border-box;

/* Auto layout */
margin-top: 15px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px;
gap: 12px;

width: 687px;
height: 140px;

/* Mono/White */

background: #FFFFFF;
/* Neutral/200 */

border: 1px solid #E6E6E6;
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
}
.Calendarcol{
  width: 100%;
  display: flex;   
  flex-direction: column;
margin-top: -10px;
}
.textCal{
        
  /* Label */


/* 14/Regular */

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 10px;
margin-left: 15px;
/* identical to box height, or 143% */

/* Neutral/900 */

color: #1C1C1C;


/* Inside auto layout */


    }
   
    input[type="checkbox"] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
     
    }
    
    .checkboxCal {
      display: inline-block;
      height: 15px;
      width: 15px;
      background: #fff;
      border: 2px #ddd solid;
      border-radius:20px;
      margin-left: 5px;
      }
    
    .checkboxCal-active {
      border-color: #FFC700 ;
      background: #FFC700;
    }
.thisweektxt{
  /* My one thing this week */


width: 179px;
height: 28px;

/* 16/Semibold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 28px;
/* identical to box height, or 175% */

display: flex;
align-items: center;

color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}


.subcontainer {
  width: 687px;
  height: 140px;
}

.subwrapper {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.subleft {
  gap: 8px;
  flex: 1;
  display: flex;
 flex-direction: column;

}
.subcenter{
  gap: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.subright{
  gap: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}


}
